#about .about1 .container .title {
  color: #1195BD;
  text-transform: uppercase;
}

#about .about1 .container .faq {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#about .about1 .container .faq details {
  padding: 1.5rem 1rem;
  border-radius: 8px;
}

#about .about1 .container .faq details.first-row {
  background-color: #1B6B84;
}

#about .about1 .container .faq details.second-row {
  background-color: #2888A6;
}

#about .about1 .container .faq details.third-row {
  background-color: #47B3D4;
}

#about .about1 .container .faq details summary {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: white;
}

#about .about1 .container .faq details summary::-webkit-details-marker {
  display: none;
}

#about .about1 .container .faq details summary:after {
  content: "\002B";
  font-size: 20px;
}

#about .about1 .container .faq details[open] summary:after {
  content: "\00D7";
}

#about .about1 .container .faq details ul {
  font-size: 16px;
  color: white;
  margin-top: 2rem;
}

#about .about1 .container .faq details ul li {
  list-style-type: disc;
  margin-left: .8rem;
  font-weight: 300;
}

#about .about1 .container .faq details ul li.mt {
  margin-top: 1rem;
}

#about .about1 .container #ctaWpp {
  margin: 0 auto;
}

#about .about2 {
  background: url("../../assets/about2.png") no-repeat center center;
  background-size: cover;
  height: 100%;
}

#about .about2 .container .title {
  color: white;
  text-transform: uppercase;
}

#about .about2 .container .gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

#about .about2 .container .gallery .item {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  border-radius: 10px;
  margin-right: -1rem;
}

#about .about2 .container .gallery .item.first {
  background-color: #1CABD7;
  z-index: 3;
}

#about .about2 .container .gallery .item.second {
  background-color: #1195BD;
  z-index: 2;
}

#about .about2 .container .gallery .item.third {
  background-color: #0687AE;
  z-index: 1;
}

#about .about2 .container .gallery .item p {
  color: white;
}

#about .about2 .container .gallery .item .item_title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 30px;
}

#about .about2 .container #ctaWpp {
  margin: 0 auto;
}

#about .about3 {
  background: url("../../assets/about3.png") no-repeat center center;
  background-size: cover;
  height: 100%;
}

#about .about3 .container {
  align-items: center;
}

#about .about3 .container .left {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 60%;
}

#about .about3 .container .left .title {
  text-align: left;
  color: #1195BD;
  text-transform: uppercase;
}

#about .about3 .container .left .text {
  color: #677678;
}

#about .about3 .container .left #ctaWpp {
  width: 70%;
}

#about .about3 .container .left #ctaWpp figure {
  width: 13%;
}

#about .about3 .container .right img {
  width: 80%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #about .about1 .container .faq details summary {
    font-size: 18px;
  }

  #about .about1 .container .faq details ul {
    font-size: 16px;
  }

  #about .about2 .container .gallery {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
  }

  #about .about2 .container .gallery .item {
    width: 80%;
    margin: 0 auto -1rem;
  }

  #about .about2 .container #ctaWpp {
    margin-top: 2rem;
  }

  #about .about2 .container .gallery .item .item_title {
    font-size: 25px;
  }

  #about .about3 .container {
    flex-direction: column;
    gap: 2rem;
  }

  #about .about3 .container .left {
    width: 100%;
  }

  #about .about3 .container .left .title {
    text-align: center;
  }

  #about .about3 .container .left .text {
    text-align: center;
  }

  #about .about3 .container .left #ctaWpp {
    width: 60%;
    margin: 0 auto;
  }

  #about .about3 .container .left #ctaWpp figure {
    width: 12%;
  }

  #about .about3 .container .right {
    order: -1;
    text-align: center;
  }

  #about .about3 .container .right img {
    width: 80%;
    margin-left: 5rem;
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #about .about1 .container .faq details summary {
    font-size: 16px;
  }

  #about .about1 .container .faq details ul {
    font-size: 14px;
  }

  #about .about2 .container {
    padding: 2rem 0;
  }

  #about .about2 .container .gallery .item {
    width: 95%;
  }

  #about .about2 .container .gallery .item .item_title {
    font-size: 20px;
  }

  #about .about3 .container .left #ctaWpp figure {
    width: 13%;
  }

  #about .about3 .container .right img {
    margin-left: 2.5rem;
  }

}