#header .header1 {
    background: url("../../assets/banner_topo.png") no-repeat center center;
    background-size: cover;
    height: 90vh;
}

#header .header1 .container {
    padding-top: 0;
    justify-content: space-between;
    height: 100%;
}

#header .header1 .container .top {
    width: 10%;
}

#header .header1 .container .title {
    font-size: 45px;
    color: white;
    width: 60%;
    text-align: left;
}

#header .header1 .container #ctaWpp {
    margin-bottom: 1rem;
}

#header #wpp {
    position: fixed;
    right: -1vw;
    top: 50vh;
    z-index: 4;
}

#header #wpp img {
    width: 70%;
}

#header .mobile {
    display: none;
}

#header .header2 {
    background-color: #F2FCFF;
}

#header .header2 .container .text {
    text-align: center;
    color: #677678;
}

#header .header2 .container #ctaWpp {
    margin: 0 auto;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #header .header1 {
        background: url("../../assets/mobile/banner_topo.png") no-repeat center center;
        background-size: cover;
        height: 70vh;
    }

    #header .header1 .container {
        justify-content: flex-start;
        gap: 4rem;
    }

    #header .header1 .container .top {
        width: 100%;
    }

    #header .header1 .container .top img {
        width: 25%;
    }

    #header .header1 .container .title {
        font-size: 32px;
        width: 100%;
        text-align: center;
    }

    #header .header1 .container #ctaWpp {
        margin-bottom: 0;
    }

    #header #wpp {
        display: none;
    }

    #header .mobile {
        display: block;
    }

    #header .mobile .container {
        display: flex;
        justify-content: center;
    }

    #header .header1 .container #ctaWpp {
        display: none;
    }

    #header .header2 .container .text {
        font-size: 20px;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #header .header1 {
        background: url("../../assets/mobile/banner_topo.png") no-repeat center center;
        background-size: cover;
        height: 60vh;
    }

    #header .header1 .container {
        gap: 2rem;
    }

    #header .header1 .container .title {
        font-size: 20px;
    }

    #header .header2 .container .text {
        font-size: 16px;
    }
}